<template>
  <b-navbar id="navbar" toggleable="sm" type="light">
    <div class="container">
      <b-navbar-brand to="/">
        <img src="@/assets/img/TWESGLOGO.png" class="img-fluid nav_img">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>關於ESG</b-nav-item>
          <b-nav-item>ESG旅行</b-nav-item>
          <b-nav-item to="/member">會員中心</b-nav-item>
          <b-nav-item v-b-modal.login_modal>登入</b-nav-item>
          <b-nav-item v-b-modal.register_modal>註冊</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
    <!-- 登入 -->
    <b-modal
      id="login_modal"
      scrollable
      title="登入"
      ok-only
      no-stacking
      ok-title="登入">
      <b-form class="text-center">
        <b-form-group
          label='帳號'
          label-for='input_account'
          label-cols="2">
          <b-form-input
          id="input_account"
          required
          type='text'
          placeholder="請輸入電子信箱"
          v-model="form.email"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label='密碼'
          label-for='input_psw'
          label-cols="2">
          <b-form-input
            id="input_psw"
            required
            type='password'
            placeholder="請輸入密碼"
            v-model="form.psw"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label='驗證碼'
          label-for='vali_code'
          label-cols="2">
          <b-row>
            <b-col cols="6">
              <b-form-input
                id="vali_code"
                required
                type='text'
                placeholder="請輸入驗證碼"
                v-model="form.vali_code"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <div @click="refreshCode" style="cursor: pointer;">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
              <!-- <img :src='require("@/captcha/captcha.php")' style="width:20px"> -->
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <div class="d-flex">
        <div class="mx-auto switch_btn mt-2" v-b-modal.register_modal>還不是會員? 點我註冊</div>
      </div>
    </b-modal>

<!-- 註冊:選擇個人或企業 -->
    <b-modal
      id="register_modal"
      scrollable
      title="註冊"
      hide-footer
      no-stacking>
      <div class="d-flex justify-content-around my-5">
        <div v-b-modal.person_register class="person mybtn d-flex flex-column p-4 p-sm-5">個人</div>
        <div v-b-modal.business_register class="business mybtn d-flex flex-column p-4 p-sm-5">企業</div>
      </div>
      <div class="d-flex">
        <div class="mx-auto switch_btn" v-b-modal.login_modal>已經是會員? 點我登入</div>
      </div>
    </b-modal>

<!-- 個人註冊 -->
    <b-modal
      id="person_register"
      scrollable
      title="個人註冊"
      ok-only
      no-stacking
      ok-title="註冊">
      <b-form class="text-center">
        <!-- <div class="mb-3">一般民眾、領隊、導遊</div> -->
        <b-form-group
          class="required"
          label='帳號'
          label-for='input_account'
          label-cols="4"
          invalid-feedback="帳號格式不正確">
          <b-form-input
          id="input_account"
          required
          type='text'
          v-model="form.account"
          placeholder="請輸入帳號"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='電子信箱'
          label-for='input_email'
          label-cols="4">
          <b-form-input
          id="input_email"
          required
          type='email'
          v-model="form.email"
          placeholder="請輸入電子信箱"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='出生日期'
          label-for='input_birth'
          label-cols="4">
          <b-form-input
            id="input_birth"
            type='date'
            v-model="form.birth"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='性別'
          label-for='input_gender'
          label-cols="4">
          <b-form-select
            type="number"
            id="input_gender"
            :options='genders'
            v-model="form.gender">
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="required"
          label='密碼'
          label-for='input_psw'
          label-cols="4">
          <b-form-input
            id="input_psw"
            required
            type='password'
            placeholder="請輸入密碼"
            v-model="form.psw"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='密碼確認'
          label-for='password'
          label-cols="4">
          <b-form-input
            id="password"
            required
            type='password'
            placeholder="請再次輸入密碼"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='驗證碼'
          label-for='vali_code'
          label-cols="4">
          <b-row>
            <b-col cols="12" class="mb-1">
              <b-form-input
                id="vali_code"
                required
                type='text'
                placeholder="請輸入驗證碼"
                v-model="form.vali_code"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <div @click="refreshCode" style="cursor: pointer;">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
              <!-- <img :src='require("@/captcha/captcha.php")' style="width:20px"> -->
            </b-col>
          </b-row>
        </b-form-group>
        <!-- <captcha></captcha> -->

        <div class="d-flex">
          <div v-b-modal.register_modal class="mx-auto my-2">上一步</div>
          <div v-b-modal.askGuide class="mx-auto my-2">-----</div>
        </div>
      </b-form>
    </b-modal>

<!-- 企業註冊 -->
    <b-modal
      id="business_register"
      scrollable
      title="企業註冊"
      ok-only
      no-stacking
      ok-title="註冊">
      <b-form class="text-center">
        <div class="mb-3 font-weight-bold">聯絡人資料</div>
        <b-form-group
          label='姓名'
          label-for='input_name'
          label-cols="4">
          <b-form-input
          id="input_name"
          required
          type='text'
          v-model="form.name"
          placeholder="請輸入姓名"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label='電子信箱'
          label-for='input_email'
          label-cols="4">
          <b-form-input
            id="input_email"
            required
            type='email'
            v-model="form.email"
            placeholder="請輸入電子信箱"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label='密碼'
          label-for='input_psw'
          label-cols="4">
          <b-form-input
            id="input_psw"
            required
            type='password'
            placeholder="請輸入密碼"
            v-model="form.psw"
        ></b-form-input>
        </b-form-group>
        <b-form-group
          label='密碼確認'
          label-for='password'
          label-cols="4">
          <b-form-input
            id="password"
            required
            type='password'
            placeholder="請再次輸入密碼"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='聯絡電話'
          label-for='input_tel'
          label-cols="4">
          <b-form-input
            id="input_tel"
            required
            type='text'
            v-model="form.tel"
            placeholder="請輸入聯絡電話"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='聯絡地址'
          label-for='input_add'
          label-cols="4">
          <b-form-input
            id="input_add"
            required
            type='text'
            v-model="form.add"
            placeholder="請輸入聯絡地址"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='行動電話'
          label-for='input_cellphone'
          label-cols="4">
          <b-form-input
            id="input_cellphone"
            type='text'
            v-model="form.cellphone"
            placeholder="請輸入行動電話"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='聯絡傳真'
          label-for='input_fax'
          label-cols="4">
          <b-form-input
            id="input_fax"
            type='text'
            v-model="form.fax"
            placeholder="請輸入聯絡傳真"
          ></b-form-input>
        </b-form-group>
        <div class="mb-3 font-weight-bold mt-4">申請單位資料</div>
        <b-form-group
          label='統一編號/工廠編號'
          label-for='unit_code'
          label-cols="4">
          <b-form-input
            id="unit_code"
            required
            type='text'
            placeholder="請輸入統一編號/工廠編號"
            v-model="form.unit_code"
        ></b-form-input>
        </b-form-group>
        <b-form-group
          label='單位中文名稱'
          label-for='unit_name'
          label-cols="4">
          <b-form-input
            id="unit_name"
            required
            type='text'
            placeholder="請輸入單位中文名稱"
            v-model="form.unit_name"
        ></b-form-input>
        </b-form-group>
        <b-form-group
          label='負責人/主管'
          label-for='manager'
          label-cols="4">
          <b-form-input
            id="manager"
            required
            type='text'
            placeholder="請輸入負責人/主管"
            v-model="form.manager"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='單位聯絡電話'
          label-for='unit_tel'
          label-cols="4">
          <b-form-input
            id="unit_tel"
            required
            type='text'
            placeholder="請輸入單位聯絡電話"
            v-model="form.unit_tel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='登記地址'
          label-for='unit_add'
          label-cols="4">
          <b-form-input
            id="unit_add"
            required
            type='text'
            placeholder="請輸入登記地址"
            v-model="form.unit_add"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label='驗證碼'
          label-for='vali_code'
          label-cols="4">
          <b-form-input
            id="vali_code"
            required
            type='text'
            placeholder="請輸入驗證碼"
            v-model="form.vali_code"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <div class="d-flex">
        <div v-b-modal.register_modal class="mx-auto my-2">上一步</div>
      </div>
    </b-modal>

<!-- 個人註冊後，詢問是否導遊 -->
    <b-modal
      id="askGuide"
      scrollable
      title="註冊成功"
      no-stacking
      hide-footer>
      <b-btn @click="goMember">如您為導遊、領隊、嚮導、售導，請點此前往填寫資料</b-btn>
    </b-modal>

  </b-navbar>
</template>

<script>
import Identify from '@/components/Identify.vue'
// import captcha from '@/captcha/captcha.php'

export default {
  components: {
    Identify
    // captcha
  },
  data () {
    return {
      identifyCode: '',
      // 驗證碼規則
      identifyCodes: '1234567890',
      form: {
        account: '',
        email: '',
        birth: '',
        gender: 'null',
        psw: '',
        vali_code: '',
        tel: '',
        add: '',
        cellphone: '',
        fax: '',
        unit_code: '',
        unit_name: '',
        manager: '',
        unit_tel: '',
        unit_add: ''
      },
      genders: [
        { value: null, text: '請選擇', disabled: true },
        { text: '男' },
        { text: '女' },
        { text: '不公開' }],
      langSelected: [],
      langOptions: ['華語', '英語'],
      roleSelected: [],
      roleOptions: ['導遊', '領隊', '嚮導', '售導']
    }
  },
  methods: {
    // 切換驗證碼
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      // console.log(this.identifyCode)
    },
    // 生成隨機驗證碼
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
      }
    },
    async goMember () {
      this.$router.push('/guide')
      this.$bvModal.hide('askGuide')
    }
  },
  // 進頁面生成隨機驗證碼
  mounted () {
    this.refreshCode()
  }
}
</script>

<style lang="scss">

#navbar {
  padding: 0 !important;
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.05);
  border-bottom: 1px solid rgb(231, 231, 231);
  .navbar-toggler {
    border: none;
  }
  .nav-item {
    margin: 0 auto;
  }
  .nav_img {
    width: 60px;
  }
  @media (min-width: 768px) {
    .nav_img {
      width: 100px;
    }
  }
}

.modal-dialog {
  .modal-header {
    justify-content: center;
    border: none;
    background-color: var(--mytheme);
    button {
      color: var(--myprimary);
    }
  }
  .modal-title {
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--mytheme2);
  }
  .form-group {
    margin-bottom: .3rem;
  }
  .modal-footer {
    border: none;
    padding: 0 0 .8rem;
    justify-content: center;
    button {
      background-color: var(--mytheme2);
      border: none;
      padding: .5rem 1.8rem;
      border-radius: 50px;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  button.close {
    position: absolute;
    right: 10px;
  }

  .mybtn {
    border-radius: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .person {
    background-color: var(--myprimary);
    color: var(--myfont);
  }
  .business {
    background-color: var(--mytheme2);
    color: #fff;
  }
  label::after {
    content: ' *';
    color: red;
  }
  // .form-group.required::after {
  //   content: '*';
  //   color: red;
  //   // left: 30px;
  //   position: relative;
  //   top: 8px;
  //   left: -322px;
  // }
}
#askGuide___BV_modal_body_ {
  display: flex;
  justify-content: center;
  button {
    max-width: 300px;
    background-color: var(--mytheme2);
    border: none;
  }
}

</style>
