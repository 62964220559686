<template>
  <div class="home">
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      img-width="1024"
      img-height="480"
      >
      <b-carousel-slide
        caption="台灣ESG旅遊"
        :img-src='require("@/assets/img/banner.jpg")'
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>

<style lang="scss">
.home {
  .carousel-item {
    // height: 480px;
  }
}</style>
